/* adjusting the built-in theme */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700;900&display=swap');

.orgcal {
    position: relative;
    margin-bottom: 20px;
}
.orgcal-monthSelect {
    position: absolute
}
.orgcal-bookings {
    /* border: 1px solid rgb(192, 192, 192); */
    display: table;
}
.orgcal-bookings-scroll {
    min-height: 241px;
    overflow-x:auto;
    overflow-y: visible;
}
.resource-row {
    display: table-row;
    /* box-shadow: 1px 0px 0px 0px rgba(36, 25, 25, 0.1) inset; */
}
.resource-column {
    display: table-cell;
    position: relative;
}
.resource-name {
    padding: 10px 20px;
    width: 140px;
}
.resource-time {
    justify-content: stretch;
    align-items: stretch;
    width: 944px;
    display: flex;
    min-height: 35px;
}
.resource-time .hour-item {
    display: block;
    width: 50px;
    box-shadow: -1px 0px 0px 0px rgba(36, 25, 25, 0) inset;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}
.resource-time .hour-item .time {
    position: absolute;
    left: -16px;
    font-size: 0.8rem;
}
.resource-time.resource-grid {
    background-color: rgba(0,0,0,0.05);
}
.resource-time.resource-grid .hour-item {
    color: rgba(255,255,255,0);
    min-height: 95px;
    box-shadow: -3px -3px 0px 0px rgba(255, 255, 255, 1) inset;
    
}

.event-item {
    display: table-cell;
    position: absolute;
    top: 3px;
    background: #ccc;
    height: calc( 100% - 6px);
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.115);
    background-color: #e0e0e0;
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1);
    background-size: 10px 10px;
    overflow: hidden;
    background-image: repeating-linear-gradient(45deg, #00000007 0, #00000007 4px, #f3f3f3 0, #f3f3f3 50%);
}
.event-item:before {
    content: '';
    display: block;
    position: absolute;
    background: rgb(196, 196, 196);
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    border-radius: 3px 0 0 3px;
}
.event-item.event-item-booking {
    background-color: rgba(229, 229, 247, 0.8);
    border: 0px solid #df399f;
    background: #fff;
    box-shadow: 1px 1px 1px rgba(121, 121, 121, 0.5);
    z-index: 888;
    height: calc( 100% - 4px);
    top: 2px;
}
.event-item.event-item-booking:before {
    background: var(--bs-purple);
}
.event-item.event-item-special-event {
    background: #e1edff;
    background-image: none;
    /* background-image: repeating-linear-gradient(45deg, #0d6dfd0e 0, #0d6dfd0e 4px, #f3f3f3 0, #f3f3f3 50%); */
}
.event-item.event-item-special-event:before {
    background: var(--bs-primary);
}
.event-tooltip {
    padding: 10px 20px;
}
.event-tooltip h5 {
    font-size: 1rem;
    font-weight: 600;
    color:rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
}
.event-tooltip p {
    color:rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
}
.event-item .event-tooltip {
    bottom: auto;
    top: 0%;
    height: 100%;
    background: #fff;
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(45deg, #00000007 0, #00000007 4px, #f3f3f3 0, #f3f3f3 50%);
    border-radius: 5px;
    color: #666;
    z-index: 999;
    padding: 10px 15px;
    min-width: 200px;
}
.event-item.event-item-special-event .event-tooltip {
    background: #e1edff;
    background-image: none;
}
.event-item.event-item-booking .event-tooltip {
    background: #fff;
    background-image: none;
}
.event-item:hover {
    z-index: 999;
    overflow: visible;
}

.special-events-item {
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    position: relative;
}
.special-events-item:before {
    content: '';
    background: var(--bs-primary);
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.special-events-item h5 {
    font-weight: 600;
}
.special-events-item .special-events-heading {
    width: 100%;
    max-width: 260px;
}
.special-events-item .special-events-heading span {
    margin-right: 5px;
}
.special-events-recurring {
    width: 100%;
    max-width: 260px;
}

.organisation-schedule-items {
    display: flex;
    justify-content: space-between;
}
.organisation-schedule-item {
    margin-right: 10px;
    width: 30%;
}

.organisation-schedule-item:last-child {
    margin-right: 0;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300; /* 700, 900 */
    background: #f9f9f9;
}

h1 {
    font-weight: 900;
    margin: 2rem 0;
}
h3 {
    font-weight: 700;
    font-size: 1.3rem;
}
.modal {
    background: rgba(0,0,0,0.3);
    z-index: 9999;
}
.org-schedules-container .modal-dialog,
.brand-organisations .modal-dialog,
.product-listing-container .modal-dialog,
.modal-wide .modal-dialog {
    max-width: 1000px;
}


.link-products .product-name {
    width: 200px;
    padding: 10px 20px 10px 0;
}

.link-products .res-name {
    width: 100px;
    padding: 10px 20px;
    text-align: center;
}
.link-products .res-name:nth-child(2n+2) {
    background: rgba(0, 0, 0, 0.03);
}
.link-res-header {
    font-weight: 600;
}

.nav.nav-pills {
    padding: 2rem;
    background: white;
    border-radius: 3px;
    box-shadow: 0px 0px 1px rgba(0,0,0,0.2);
}

.card {
    box-shadow: 0px 0px 1px rgba(0,0,0,0.2);
    border: 0;
}
.card-header {
    background: #fff;
    border-color: rgba(0, 0, 0, 0.1)
}
.today-label {
    margin-bottom: 0;
    font-weight: 500;
}

.navigator_default_title, 
.navigator_default_titleleft, 
.navigator_default_titleright,
.navigator_default_main {
    border: 0;
    border-color: transparent;
    background: none;
}
.navigator_default_title, 
.navigator_default_titleleft, 
.navigator_default_titleright {
    font-weight: 600;
    font-size: 1rem;
}
.navigator_default_weekend {
    background: none;
}
.navigator_default_dayother {
    color: rgba(0,0,0,0.1);
}
.navigator_default_month {
    font-family: 'Source Sans Pro', sans-serif;
}
.navigator_default_dayheader {
    color: rgba(0,0,0,0.3);
    font-weight: 600;
}
.navigator_default_select .navigator_default_cell_box {
    border: 0;
    background: var(--bs-primary);
    opacity: 1;
    color: #fff;
    border-radius: 25px;
}
.navigator_default_select .navigator_default_cell_text {
    color: #fff;
    font-weight: 600;
}
.navigator_default_todaybox {
    border-radius: 25px;
    border-color: rgba(0,0,0,0.2);
}
.navigator_default_line {
    display: none;
}

.brand {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    position: relative;
    min-height: 100px;
}

.hoverLabel {
    text-transform: uppercase;
    font-size: 0.8rem;
    opacity: 0;
    width: 200px;
    display: inline-block;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.list-group-target:hover .hoverLabel {
    opacity: 0.5;
}
.list-group-target {
    cursor: pointer;
}
.childcatlist:nth-child(1) {
    margin-top: 12px;
}
.childcatlist > div {
    padding: 5px 1rem;
    margin: 0 -1rem;
}
.childcatlist > div h5 {
    font-size: 1rem;
    margin-bottom: 0;
}
.childcatlist > div:nth-child(2n+1) {
    background: rgba(0, 0, 0, 0.03);
}


/* loading */
.loading-spinner-container {
    position: absolute;
    z-index: 999;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ccc transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

/* end loading */
  