.rounded-circle {
    border-radius: 5px;
}
.border-info {
    border: 0px solid #dee2e6 !important;
}
.border {
    border: 0px solid #dee2e6 !important;
    color: #ccc !important;
}
.border-primary {
    border: 0px solid #dee2e6 !important;
    background: #999;
    color: #fff;
}
.btn {
    /* border: 1px solid #ccc; */
}
.btn.btn-link {
    border: 0;
}
.btn.btn-link.btn-sm.btn-danger {
    padding: 0;
    background: 0;
    color: #dc3545;
}
/* .close {
    position: absolute;
    right: 0;
    top: 0;
    border: 3px solid #333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #fff;
    color: #333;
    font-size: 1.5rem;
    line-height: 0rem;
    padding: 0;
}
.close span {
    position: relative;
    top: -2px;
} */

.mt-2.mr-1 > div > div {
    display: inline-block;
    width: 45% !important;
    margin-right: 10px;
}
.mt-2.mr-1 > div > div .btn-primary {
    display: inline-block;
    min-width: 50% !important;
    width: 100%;
    background: #333;
}

.loading-indicator {
    position:fixed;
    width:100%;
    height:100%;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.modal {
    display: block;
}